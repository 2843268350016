@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
/* @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap'); */



body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  background-color: #fdfdfd;
}
.Toastify__toast-body {
  font-family: 'Montserrat', sans-serif !important;
  font-size: 12px !important;
  font-weight: 500;
}
.Toastify__toast-icon {
  margin-inline-end: 15px !important;
  width: 30px !important;
}

.react-tel-input .form-control {
  font-size: 16px !important;
  padding: 23px 15px 23px 50px !important;
  width: 100% !important;
  font-family: 'Montserrat', sans-serif;
  border: 1px solid #9ca3af !important;
}

.react-tel-input .form-control.danger {
  border: 1px solid #b91c1c !important;
}

.react-tel-input .form-control.danger~.flag-dropdown {
  border: 1px solid #b91c1c !important;
}

.react-tel-input .flag-dropdown {
  border: 1px solid #9ca3af !important;
}

.auth-bg-1 {
  background-image: url('../img/5120324.jpg');
  background-blend-mode: lighten;
  background-attachment: fixed;
  background-size: cover;
  background-position: 10% 50%;
  background-repeat: repeat;
  background-color: #dbdbdb;
}


.loader {
  border-top-color: #00d463; /* Tailwind primary color */
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}


@keyframes flash {

  0%,
  50%,
  100% {
    opacity: 1;
  }

  25%,
  75% {
    opacity: 0;
  }
}



.otp-input-container {
  display: flex;
  gap: 10px; /* Space between input fields */
}

.otp-input {
  width: 40px;
  height: 40px;
  text-align: center;
  font-size: 18px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.2s;
}

.otp-input:focus {
  border-color: #007bff; /* Color of border on focus */
}





.animate-flash {
  animation: flash 1.5s infinite;
}


/* sweet alert custom */
.swal2-popup.swal2-modal.swal2-show {
  width: auto !important;
}

.swal2-popup.swal2-modal.swal2-icon-error.swal2-show,
.swal2-popup.swal2-modal.swal2-icon-success.swal2-show {
  width: 32em !important;
}

div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm {
  border: 0;
  border-radius: 0.25em;
  background: initial;
  background-color: #1500ff !important;
  color: #fff;
  font-size: 1em;
}

div:where(.swal2-container) button:where(.swal2-styled).swal2-default-outline:focus {
  box-shadow: 0 0 0 3px rgb(0 127 255 / 50%) !important;
}




/* layout styling custom */
.nav-area {
  width: 230px;
  position: fixed;
  overflow-y: scroll;
  top: 65px;
}

.nav-area::-webkit-scrollbar {
  display: none;
  /* Width of the scrollbar */
}

.nav-area:hover::-webkit-scrollbar {
  display: block;
  width: 3px;
  /* Width of the scrollbar */
}

.nav-area::-webkit-scrollbar-thumb {
  background-color: #b6b6b6;
  /* Color of the thumb (handle) */
}

.nav-area::-webkit-scrollbar-track {
  background-color: #CBD5E0;
  /* Color of the track (background) */
}

.nav-area ul li label {
  color: #32353f;
  font-size: 14px;
  margin-top: 1px;
}

.nav-area ul li svg {
  height: 20px;
  width: 20px;
}

.nav-area ul li svg path{
  stroke: #433939;
}

.nav-area li.active a {
  background: #0351d7;
  border-radius: 5px;
}

.nav-area li.active a label {
  color: #f2f2f2;
}

.nav-area li.active a svg path {
  stroke: orange !important;
}

.main-container {
  width: calc(100% - 230px);
}


.avatar-title {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #5052ba;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-weight: 500;
  height: 100%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
}

.bg-soft-primary {
  background-color: rgba(80, 82, 186, .25) !important;
}

.avatar-md {
  height: 3.4rem;
  width: 3.4rem;
}

.avatar-md svg {
  stroke: #0053e3;
}


/* Logo Text Styles */
.logo-text {
  font-size: 24px;
  font-weight: bold;
  margin-right: 10px;
  /* Adjust spacing between text and icon */
}

/* Logo Icon Styles */
.logo-icon {
  width: 30px;
  /* Adjust icon size */
  height: 30px;
  fill: #007bff;
  /* Adjust icon color */
}

.css-13cymwt-control {
  height: 38px !important;
  font-size: 12px;
}

input,
textarea,
select {
  border-color: #ccc;
}

table th {
  vertical-align: top;
  /* Align text to the bottom of the th element */
  direction: rtl;
}

/* custom tabs */
.nav-tabs {
  display: flex;
  list-style-type: none;
  padding: 0;
}

.nav-item {
  flex: 1;
  text-align: center;
}

.nav-link {
  border-bottom: 1px solid #a1a1a1;
  border-radius: 0.25rem 0.25rem 0 0;
  padding: 15px 1rem;
  cursor: pointer;
  font-weight: normal;
  background-color: #8ea1ff17;
  color: #707070;
}

.nav-link.active {
  background-color: #ffffff;
  color: #1d4ed8;
  font-weight: 500;
  border: 2px solid #cecece;
  border-bottom: none;
}

.tab-content {
  border: 1px solid #dee2e6;
  border-top: none;
  border-radius: 0 0 0.25rem 0.25rem;
  padding: 1rem;
}

.tab-pane {
  display: none;
}

.tab-pane.active {
  display: block;
}

.card-border-no-top {
  border: 2px solid #cecece;
  border-top: none;
}

.swal2-timer-progress-bar {
  background: rgba(230, 42, 42, 0.532) !important;
}

.css-13cymwt-control {
  /* height: 38px !important; */
  font-size: 14px;
  font-weight: 500;
}

.css-13cymwt-control {
  overflow: hidden !important;
}

td.description {
  max-width: 200px;
  /* Set a maximum width for the table cell */
}

td.title {
  max-width: 130px;
  /* Set a maximum width for the table cell */
}

td.ellipsis {
  white-space: nowrap;
  /* Prevent text from wrapping to the next line */
  overflow: hidden;
  /* Hide any overflowing text */
  text-overflow: ellipsis;
  /* Display an ellipsis (...) for overflowed text */
}

.side-user-icon {
  background: #f1f1f1;
  border-radius: 50%;
  padding: 1px;
  margin: 4px;
}

.react-datepicker-wrapper {
  width: 100%;
}

.css-1jqq78o-placeholder {
  color: hsl(0deg 0% 50% / 51%) !important;
}

.iconEye {
  position: absolute;
  top: 27px;
  right: 10px;
}

.text-capitalize {
  text-transform: capitalize;
}

.style-seacrh {
  position: absolute;
  top: 11px;
  right: 15px;
}

input:focus,
select:focus {
  outline: none;
}

.txt-ellipsis {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 200px;
}


.w1 {
  stroke: none;
  fill: #f9fafb;
  opacity: 99%;
  animation: move1 5s ease-in-out infinite;
}

.w2 {
  stroke: none;
  fill: #f9fafb;
  opacity: 75%;
  transform: translate3d(0, 0, 0);
  animation: move2 4s ease-in-out infinite;
}

.w3 {
  stroke: none;
  fill: #f9fafb;
  opacity: 60%;
  transform: translate3d(0, 0, 0);
  animation: move3 6s ease-in-out infinite;
}

@keyframes move1 {
  0% {
    transform: translateX(-500px) scaleX(2.5);
  }

  25% {
    transform: translateX(-100) scaleX(2.5);
  }

  50% {
    transform: translateX(0) scaleX(2.5);
  }

  75% {
    transform: translateX(-100) scaleX(2.5);
  }

  100% {
    transform: translateX(-500px) scaleX(2.5);
  }
}

@keyframes move2 {
  0% {
    transform: translateX(-600px) scaleX(3);
  }

  25% {
    transform: translateX(-100) scaleX(2.5);
  }

  50% {
    transform: translateX(0) scaleX(3);
  }

  75% {
    transform: translateX(-100) scaleX(2.5);
  }

  100% {
    transform: translateX(-600px) scaleX(3);
  }
}

@keyframes move3 {
  0% {
    transform: translateX(-800px) scaleX(3);
  }

  25% {
    transform: translateX(-100) scaleX(2.5);
  }

  50% {
    transform: translateX(0) scaleX(3);
  }

  75% {
    transform: translateX(-100) scaleX(2.5);
  }

  100% {
    transform: translateX(-800px) scaleX(3);
  }
}